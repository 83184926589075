import React from 'react'
import {
  Grid,
  makeStyles,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
  Tooltip,
} from '@material-ui/core'
import { FieldProps, getIn } from 'formik'
import CaptionText from '../CaptionText'
import ReactMarkdown from 'react-markdown'
import InfoIcon from '@material-ui/icons/Info'
import { LabelValuePair } from 'types/generalTypes'

interface Props {
  name: string
  question: string
  hint?: string
  toolTip?: string
  options: LabelValuePair[]
  defaultValue?: string
  row?: boolean
  onSelect?: (value: string) => void
}

const useStyles = makeStyles(theme => ({
  inputArea: {
    width: theme.spacing(35),
    justifyContent: 'flex-start',
  },
  question: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      paddingRight: 0,
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      paddingRight: theme.spacing(10),
    },

    // First paragraph, remove margin so its level with other elements
    '& :nth-child(1)': {
      marginTop: 0,
    },
  },
  radioGroup: {
    justifyContent: 'flex-start',
  },
  radioInRow: {
    justifyContent: 'flex-start',
    margin: theme.spacing(0),
    padding: '0 5px',
  },
  radioInColumn: {
    width: '100%',
    margin: `${theme.spacing(2)}px 0 0 0`,
    '&:first-child': {
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      paddingLeft: 9,
    },
  },
  toolTip: {
    marginLeft: 5,
  },
}))

// TODO: Took away the border from the radio select boxes. Need to check this is ok (personally think it looks better)
// REASONING: Material UI doesnt have this by default so styles like hover, active, disabled, error - dont get applied
// by default either. Can be added of course but not be worth it (yet) due to time.

const QuestionWithRadioBtns = ({
  form,
  field,
  name,
  question,
  hint,
  toolTip,
  options,
  row = false,
  onSelect,
  ...props
}: Props & FieldProps) => {
  const classes = useStyles()
  const error = getIn(form.errors, field.name)
  const touched = getIn(form.touched, field.name)
  const submitCount = form.submitCount
  const errorText = error && touched ? error : null

  return (
    <>
      <Typography component="div" style={{ display: 'flex' }}>
        <ReactMarkdown linkTarget="_blank">{question}</ReactMarkdown>

        {toolTip && (
          <Tooltip title={toolTip} className={classes.toolTip}>
            <InfoIcon fontSize="small" />
          </Tooltip>
        )}
      </Typography>
      {hint && <CaptionText hint={hint} noTopMargin />}
      <FormControl variant="outlined" size="small" className={classes.inputArea} error={!!errorText} {...props}>
        <RadioGroup
          row={row}
          className={classes.radioGroup}
          name={field.name || undefined}
          value={field.value || ''}
          onChange={event => {
            const { value } = event.target
            onSelect && onSelect(value)
            form.setFieldValue(field.name, value, submitCount > 0)
          }}
        >
          {options.map(option => (
            <FormControlLabel
              className={row ? classes.radioInRow : classes.radioInColumn}
              key={option.value}
              value={option.value}
              checked={option.value === field.value}
              control={<Radio color="secondary" />}
              label={option.label}
            />
          ))}
        </RadioGroup>
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </>
  )
}

export default QuestionWithRadioBtns
