import { validateYupSchema } from 'formik'
import moment from 'moment'
import * as yup from 'yup'

const validationSchema = () =>
  yup.object().shape({
    registrationNumber: yup.string().when(['regNumUnknown'], {
      is: (regNumUnknown: boolean) => regNumUnknown === false,
      then: yup
        .string()
        .required('Please complete the field')
        .test(
          'value',
          'We could not find this car. Please provide your car registration number or enter your car details manually',
          value =>
            value !== undefined &&
            /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][1-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[A-Z]{4}[0-9]{1,3}[A-Z]$)|(^[1-9]{1}[0-9]{1,3}[A-Z]{1,2}$)|(^[1-9]{1}[0-9]{1,2}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[1-9]{1}[A-Z]{1}$)|(^[1-9]{1}[A-Z]{2}$)|(^[1-9]{1}[A-Z]{3}$)|(^[1-9]{1}[0-9]{2}[DX]{1}[0-9]{2}$)|^[A-Z]{2}[0-9]{2}[A-Z]{2}|[1-9]{1}[0-9]{1}[A-Z]{2}[0-9]{2}/.test(
              value.toUpperCase().trim(),
            ),
        ),
    }),
    carPurchaseDate: yup
      .string()
      .required('Please complete the field')
      .nullable()
      .when(['regNumUnknown'], {
        is: (regNumUnknown: boolean) => regNumUnknown === false,
        then: yup
          .string()
          .nullable()
          .required('Please complete the field')
          .test(
            'value',
            'We cannot provide a quote for vehicle you intend to purchase if it is more than 30 days in the future',
            value => moment(value).isSameOrBefore(moment(new Date()).add(30, 'day')),
          )
          .test('value', 'Invalid date', value => moment(value).isValid()),
      }),
    marketValue: yup.string().required('Please complete the field'),
    hasBeenModified: yup.string().test('value', 'Please choose one of the possible options', value => value !== 'none'),
    modification: yup
      .string()
      .nullable()
      .when(['hasBeenModified'], {
        is: (hasBeenModified: string) => hasBeenModified === 'yes',
        then: yup
          .string()
          .nullable()
          .required('Please complete the field'),
      }),
    securityDevices: yup.string().test('value', 'Please choose one of the possible options', value => value !== 'none'),
    nightTimeParking: yup
      .string()
      .required()
      .test('value', 'Please choose one of the possible options', value => value !== 'none'),
    dayTimeParking: yup
      .string()
      .required()
      .test('value', 'Please choose one of the possible options', value => value !== 'none'),
    classOfUse: yup.string().required('Please choose one of the possible options'),
    registeredKeeper: yup.string().required('Please choose one of the possible options'),
    securityTracker: yup.string().required('Please choose one of the possible options'),
    imported: yup.string().required('Please choose one of the possible options'),
    rightHandDrive: yup.string().required('Please choose one of the possible options'),
    lessThanEightSeats: yup.string().required('Please choose one of the possible options'),
  })

export const initialValues = {
  // car
  registrationNumber: '',
  carPurchaseDate: null,
  marketValue: '',
  hasBeenModified: 'none',
  modification: 'none',
  securityDevices: 'none',
  nightTimeParking: 'none',
  dayTimeParking: 'none',
  classOfUse: '',
  registeredKeeper: '',
  securityTracker: '',
  imported: '',
  rightHandDrive: '',
  lessThanEightSeats: '',
}

export default validationSchema
