import { connect } from 'react-redux'
import { confirmMTAChangesAction, cancelMTAChangesAction } from 'redux/mta/actions'

import { IReduxState } from 'types/stateTypes'
import MTAModal from './MTAConfirmModal'

const mapStateToProps = (state: IReduxState) => ({
  current: state.policy.current,
  startDate: state.mta.startDate,
  changedAddress: state.mta.changedAddress,
  homeOwner: state.mta.homeOwner,
  dayTimeParking: state.mta.dayTimeParking,
  nightTimeParking: state.mta.nightTimeParking,
  addedDrivers: state.mta.addedDrivers,
  changedCar: state.mta.changedCar,
  carPurchaseDate: state.mta.carPurchaseDate,
  marketValue: state.mta.marketValue,
  hasBeenModified: state.mta.hasBeenModified,
  securityDevices: state.mta.securityDevices,
  classOfUse: state.mta.classOfUse,
  registeredKeeper: state.mta.registeredKeeper,
  securityTracker: state.mta.securityTracker,
  imported: state.mta.imported,
  rightHandDrive: state.mta.rightHandDrive,
  lessThanEightSeats: state.mta.lessThanEightSeats,
  showMTAConfirmModal: state.mta.showMTAConfirmModal,
  registration: state.car.registration,
})

const mapDispatchToProps = {
  confirmMTAChangesAction: confirmMTAChangesAction.start,
  cancelMTAChangesAction: cancelMTAChangesAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(MTAModal)
