import React from 'react'
import { FormikProps, Formik, Field, FieldProps } from 'formik'

import { Box, Button, Grid, makeStyles } from '@material-ui/core'

import Section from 'components/ui/Section'
import Layout from 'components/ui/Layout'
import CarDetailsFromLookupSection from 'components/ui/CarDetailsFromLookupSection'
import QuestionWithDatePicker from 'components/ui/QuestionWithDatePicker'
import QuestionWithRadioBtns from 'components/ui/QuestionWithRadioBtns'
import QuestionWithSelector from 'components/ui/QuestionWithSelector'
import QuestionWithTextField from 'components/ui/QuestionWithTextField'
import SearchRegistrationSection from 'components/ui/SearchRegistrationSection'

import { isEmpty } from 'guidewire/formatTools'

import validationSchema, { initialValues } from './validationSchema'

import { EndPoint } from 'types/endpoint'
import { Driver, SelectedPolicy, VehicleType } from 'types/policy'
import { LabelValuePair } from 'types'

const useStyles = makeStyles(theme => ({
  infoContainer: {
    textAlign: 'left',
    display: 'flex',
  },
  infoText: {
    marginTop: 0,
    paddingLeft: 12,
  },
  infoLink: {
    textDecoration: 'none',
  },
  bottomText: {
    marginLeft: 24,
  },
  label: {
    marginBottom: 13,
  },
  readOnlyLabel: {
    marginBottom: 13,
    color: theme.palette.text.disabled,
  },
  questionBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}))

const carSecurityFeaturesOptions = [
  {
    label: 'None',
    value: 'None',
  },
  {
    label: 'Alarm',
    value: 'Alarm',
  },
  {
    label: 'Immobiliser',
    value: 'Immobiliser',
  },
  {
    label: 'Alarm and immobiliser',
    value: 'AlarmAndImmobiliser',
  },
]

const nightParkingOptions = [
  {
    label: 'Garaged Overnight',
    value: 'GaragedOvernight',
  },
  {
    label: 'On Drive',
    value: 'OnDrive',
  },
  {
    label: 'Public Road',
    value: 'PublicRoad',
  },
  {
    label: 'Secure Car Park',
    value: 'SecureCarPark',
  },
  {
    label: 'Unsecured Off Road Parking',
    value: 'UnsecuredOffRoadParking',
  },
]

const daytimeParkingOptions = [
  {
    label: 'Garaged At Home',
    value: 'GaragedAtHome',
  },
  {
    label: 'On Drive At Home',
    value: 'OnDriveAtHome',
  },
  {
    label: 'Public Road At Home',
    value: 'PublicRoadAtHome',
  },
  {
    label: 'Secure Car Park',
    value: 'SecureCarPark',
  },
  {
    label: 'Station Car Park',
    value: 'StationCarPark',
  },
  {
    label: 'Public Road Away From Home',
    value: 'PublicRoadAwayFromHome',
  },
  {
    label: 'Other Unsecured Car Park',
    value: 'OtherUnsecuredCarPark',
  },
  {
    label: 'Unsecured Off Road Parking',
    value: 'UnsecuredOffRoadParking',
  },
]

const carUsageOptions = [
  {
    label: 'Social Domestic and Pleasure only (including commuting)',
    value: 'SDAndPleasureOnly',
  },
  {
    label: 'SD and P + business use by you the policyholder and any named driver',
    value: 'SDAndPPBU',
  },
  {
    label: 'SD and P + business use, including commercial travelling, by you the policyholder and any named driver',
    value: 'SDAndPPBUICT',
  },
]

const yesNoOptions: LabelValuePair[] = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
]

const modificationOptions: LabelValuePair[] = [
  {
    value: 'AlloyWheels',
    label: 'Alloy wheels',
  },
  {
    value: 'WindowTints',
    label: 'Window tints',
  },
  {
    value: 'Graphics',
    label: 'Graphics',
  },
  {
    value: 'Spoiler',
    label: 'Spoiler',
  },
  {
    value: 'BodyKit',
    label: 'Body kit',
  },
  {
    value: 'ChangeToSuspensionBrakes',
    label: 'Change to suspension/brakes',
  },
  {
    value: 'ChangeToEngineOrEngineManagement',
    label: 'Change to engine/engine management',
  },
  {
    value: 'ChangeToExhaustSystem',
    label: 'Change to exhaust system',
  },
  {
    value: 'InternalCosmeticChange',
    label: 'Internal cosmetic change',
  },
  {
    value: 'SeveralOfTheAbove',
    label: 'Several of the above',
  },
  {
    value: 'OtherModificationNotListed',
    label: 'Other modification not listed',
  },
]

export interface Props {
  formValues: any
  vehicle: any
  selectedVehicle: VehicleType | undefined
  vehicleLookupError: boolean
  registration: string | undefined
  quote: SelectedPolicy
  additionalDrivers: Driver[]
  addedDrivers: Driver[]
  current: any
  getVehicleByRegistrationAction: (regi: string) => void
  continueRenewalAction: (values: any) => void
  quoteRenewalAction: (values: any) => void
}

const RenewalYourCarPage = ({
  formValues,
  vehicle,
  selectedVehicle,
  vehicleLookupError,
  registration,
  quote,
  additionalDrivers,
  addedDrivers,
  current,
  getVehicleByRegistrationAction,
  continueRenewalAction,
  quoteRenewalAction,
}: Props) => {
  const formRef = React.useRef<FormikProps<any>>(null)
  const [continueSubmit, setContinueSubmit] = React.useState(true)
  const classes = useStyles()

  const handleSubmit = (values: any) => {
    if (formRef?.current?.isValid) {
      //set new vehicle if selected
      let quoteVehicle = vehicle
      if (!isEmpty(selectedVehicle)) {
        quoteVehicle = {
          ...vehicle,
          registrationNumber: registration,
          ...selectedVehicle,
        }
      }
      if (continueSubmit) {
        quoteRenewalAction({
          formValues: values,
          vehicle: quoteVehicle,
          quote: quote,
          additionalDrivers: additionalDrivers,
          addedDrivers: addedDrivers,
          current: current,
        })
      } else {
        continueRenewalAction({
          formValues: values,
          vehicle: quoteVehicle,
          redirect: EndPoint.RENEWAL_ABOUT_YOU,
        })
      }
    }
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={isEmpty(formValues) === false ? formValues : initialValues}
      onSubmit={async values => handleSubmit(values)}
      validationSchema={validationSchema()}
      validateOnBlur={false}
      enableReinitialize
      isInitialValid={true}
    >
      {props => (
        <>
          <Layout background="white">
            <Section>
              <Section title="Car details">
                <Field name="registrationNumber">
                  {({ field, form }: FieldProps) => (
                    <SearchRegistrationSection
                      form={form}
                      field={field}
                      onVehicleLookup={(registration: string) => {
                        getVehicleByRegistrationAction(registration.toUpperCase())
                      }}
                      vehicleLookupError={vehicleLookupError}
                    />
                  )}
                </Field>

                <Box pb={5}>
                  <CarDetailsFromLookupSection
                    vehicle={isEmpty(selectedVehicle) ? vehicle : selectedVehicle}
                    registration={registration ? registration : vehicle.registrationNumber}
                  />
                </Box>

                <Box pt={2.5} pr={2.5} pl={2.5}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={4} className={classes.questionBox}>
                      <Field
                        required
                        component={QuestionWithDatePicker}
                        name="carPurchaseDate"
                        question={'When did you buy the car?'}
                        hint={''}
                        toolTip={''}
                        format="MM / yyyy"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={QuestionWithTextField}
                        name="marketValue"
                        type="number"
                        question={'What is the current market value of the car (£)? '}
                        hint={''}
                        toolTip={
                          'This is the cost or replacement with one of the same make, model and specification, considering the age, mileage and condition.                            '
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box pt={2.5} pr={2.5} pl={2.5} pb={4}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={QuestionWithRadioBtns}
                        row
                        name="hasBeenModified"
                        question={'Has your car been modified?'}
                        hint={''}
                        toolTip={
                          'This does not include modifications by the manufacturer or for a disability. Contact us if you have questions about a specific modification.'
                        }
                        options={yesNoOptions}
                      />
                    </Grid>
                    {props.values['hasBeenModified'] === 'yes' && (
                      <Grid item xs={12} sm={4}>
                        <Field
                          component={QuestionWithSelector}
                          row
                          name="modification"
                          question={'Choose modification type'}
                          hint={''}
                          options={modificationOptions}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
                <Box pt={2.5} pr={2.5} pl={2.5}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={4} className={classes.questionBox}>
                      <Field
                        component={QuestionWithSelector}
                        name="securityDevices"
                        question={'What security devices does this car have?'}
                        hint={''}
                        toolTip={''}
                        options={carSecurityFeaturesOptions}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.questionBox}>
                      <Field
                        component={QuestionWithSelector}
                        name="nightTimeParking"
                        question={'Where is your car parked overnight?'}
                        hint={''}
                        toolTip={''}
                        options={nightParkingOptions}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={QuestionWithSelector}
                        name="dayTimeParking"
                        question={'Where is your car parked during the day?'}
                        hint={''}
                        toolTip={''}
                        options={daytimeParkingOptions}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box pt={2.5} pr={2.5} pl={2.5}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={8}>
                      <Field
                        component={QuestionWithSelector}
                        name="classOfUse"
                        question={'How do you use your car?'}
                        hint={''}
                        toolTip={
                          'Incorrect car usage may affect any claim you make or could result in your insurance being invalid.'
                        }
                        options={carUsageOptions}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box pt={2.5} pr={2.5} pl={2.5}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={8}>
                      <Field
                        component={QuestionWithRadioBtns}
                        row
                        name="registeredKeeper"
                        question={'Will you* be the owner and registered keeper of the car?'}
                        hint={'*or your spouse, civil partner or common law partner'}
                        toolTip={
                          'The owner is the person who has legal title to the car. The registered keeper is the person or organisation recorded by the DVLA as the legal keeper of the car.'
                        }
                        options={yesNoOptions}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box pt={2.5} pr={2.5} pl={2.5} pb={4}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={QuestionWithRadioBtns}
                        row
                        name="securityTracker"
                        question={'Does this car have an active security tracker?'}
                        hint={''}
                        toolTip={''}
                        options={yesNoOptions}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={QuestionWithRadioBtns}
                        row
                        name="imported"
                        question={'Has the car been imported?'}
                        hint={''}
                        toolTip={''}
                        options={yesNoOptions}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box pt={2.5} pr={2.5} pl={2.5} pb={4}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={QuestionWithRadioBtns}
                        row
                        name="rightHandDrive"
                        question={'Is the car right hand drive?'}
                        hint={''}
                        toolTip={''}
                        options={yesNoOptions}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={QuestionWithRadioBtns}
                        row
                        name="lessThanEightSeats"
                        question={'Does the car have less than 8 seats?'}
                        hint={''}
                        toolTip={''}
                        options={yesNoOptions}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Section>

              <Grid item xs={12}>
                <Section>
                  <Box pl={2.5} pr={2.5} pb={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Field
                          required
                          component={QuestionWithTextField}
                          name="ncdYears"
                          disableFuture
                          dob
                          question={'How many years No Claim Discount can you prove?'}
                          hint={''}
                          toolTip={''}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Section>
              </Grid>
            </Section>
          </Layout>

          <Layout background="#eff0f0">
            <Box pt={8} pr={2.5} pl={2.5} pb={4}>
              <Grid container justify="center" spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setContinueSubmit(false)
                      const submit = async () => await props.submitForm()
                      submit()
                    }}
                  >
                    Go back
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setContinueSubmit(true)
                      const submit = async () => await props.submitForm()
                      submit()
                    }}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Layout>
        </>
      )}
    </Formik>
  )
}

export default RenewalYourCarPage
