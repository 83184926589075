import { Box, Button, Grid, Typography } from '@material-ui/core'
import { v4 as uniqeID } from 'uuid'

import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'

import Layout from 'components/ui/Layout'
import Section from 'components/ui/Section'
import Title from 'components/ui/Title'
import SummaryAccordion from 'components/ui/SummaryAccordion'
import RenewalPolicyDocuments from 'components/document/RenewalPolicyDocuments'

import { history } from 'utils'
import { renewalQuote } from 'guidewire/policy/quote/renewalQuote'

import { BillingId } from 'guidewire/policy/enums/billingId'
import { LabelValuePair } from 'types'

import documentContent from 'mock/cms/renewal_document_page.json'
import { dateObjectToMoment } from 'guidewire/formatTools'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'

export interface Props {
  quote: any
  billingId: string
}

const RenewalDocuments = ({ quote, billingId }: Props) => {
  const { documentsForAllPolicies, documentsForAddons, documentsForBreakdown } = documentContent

  const gw = renewalQuote(quote)
  const addons = gw.getIncludedAddons.getQuoteAddonSummary()

  // This can be confusing. It just loops through addons to see if a breakdown level has been selected
  // returned values are level1, level2, level3 or undefined
  // if not undefined, the level is used to retrieve the correct document link from documentsForBreakdown content
  const getBreakdownDoc = () => {
    const breakdownDocumentLink: string | undefined = (documentsForBreakdown as any)[
      (addons
        .filter(item => item.codeIdentifier.includes('Level'))
        .find(item => item.selected)
        ?.codeIdentifier.toLowerCase() as string) || ''
    ]

    if (breakdownDocumentLink !== undefined) {
      return {
        label: documentsForBreakdown.label,
        documentUrl: breakdownDocumentLink,
      }
    } else return undefined
  }

  const onContinue = () => {
    if (billingId === BillingId.ANNUAL) {
      history.push('/renewal-payment-annual')
    }
    if (billingId === BillingId.INSTALLMENT) {
      history.push('/renewal-payment-monthly')
    }
  }

  const getSummaryContent = (id: string) => {
    switch (id) {
      case 'getVehicle':
        const vehicle = gw.getVehicle.display()

        return [
          { label: 'Registration', value: `${vehicle.registrationNumber}` },
          { label: 'Fuel type', value: `${vehicle.fuelType}` },
          { label: 'Purchased date', value: `${vehicle.purchasedDate}` },
          { label: 'Year of registration', value: `${vehicle.firstRegisteredYear}` },
          { label: 'Market value', value: `${vehicle.marketValue}` },
          { label: 'Estimated annual mileage', value: `${vehicle.annualMileage}` },
          { label: 'Security devices', value: `${vehicle.securityDevices}` },
          { label: 'Security tracker', value: `${vehicle.securityTracker}` },
          { label: 'Car modified', value: `${vehicle.hasBeenModified}` },
          { label: 'Right hand drive', value: `${vehicle.rightHandDrive}` },
          { label: 'Less than 8 seats', value: `${vehicle.lessThanEightSeats}` },
          { label: 'Car imported', value: `${vehicle.imported}` },
          { label: 'Registered keeper and owner of the car', value: `${vehicle.registeredKeeper}` },
        ] as LabelValuePair[]

      case 'getCover':
        const baseData = gw.getBaseData.display()
        const classOfUse = gw.getVehicle.display().classOfUse

        return [
          { label: 'Cover start date', value: `${baseData.periodStartDate}` },
          { label: 'Expiry date', value: `${baseData.periodEndDate}` },
          { label: 'Cover level', value: `${baseData.productCode}` },
          { label: 'How do you use your car?', value: `${classOfUse}` },
        ] as LabelValuePair[]

      case 'getPolicyHolder':
        const accountHolder = gw.getAccountHolder.display()

        return [
          { label: 'Policyholder', value: accountHolder.displayName },
          { label: 'Date of birth', value: accountHolder.dateOfBirth },
          { label: 'Primary occupation', value: accountHolder.occupation },
          { label: 'Address', value: accountHolder.primaryAddress.displayName },
          { label: 'Homeowner', value: accountHolder.homeOwner },
        ] as LabelValuePair[]

      case 'getDrivers':
        const drivers = gw.getDrivers.details()

        return drivers.map(item => {
          const driver = gw.getDrivers.display(item)
          return [
            {
              label: 'Name',
              value:
                driver.mainDriver === 'Yes'
                  ? `<div>${driver.displayName}</div><div>MAIN DRIVER</div> `
                  : `${driver.displayName}`,
            },
            { label: 'Primary occupation', value: `${driver.occupation}` },
            { label: 'Marital status', value: `${driver.maritalStatus}` },
            { label: 'UK residency', value: `${driver.ukResident5Years}` },
            { label: 'Licence type', value: `${driver.licenseType}` },
            { label: 'Access to other cars', value: `${driver.accessToOtherVehicles}` },
            { label: 'Additional driving qualifications', value: `${driver.drivingQualifications}` },
            { label: 'Medical conditions', value: `${driver.medicalConditions}` },
            { label: 'Unspent non-motoring offences', value: `${driver.nonMotoringConvictions}` },
            { label: 'Previous cancelled insurance by an insurer', value: `${driver.cancelledPreviousInsurance}` },
            { label: 'Convictions', value: `${driver.hasMotorConvictions}` },
            { label: 'Claims', value: `${driver.hasMotorClaims}` },
          ] as LabelValuePair[]
        })

      default:
        break
    }
  }

  const now = dateObjectToMoment(quote.baseData.periodStartDate)

  const showNew = moment('20231229', 'YYYYMMDD')

  return (
    <>
      <Layout background="white">
        <Section>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Title
                title="Please make sure we have everything correct"
                customerName={gw.getAccountHolder.details().firstName}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ fontSize: 20 }}>
                {"Please check the details below to make sure the cover you've selected meets your needs."}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {([
                {
                  label: 'Your car',
                  value: gw.getVehicle.valuesAsString(['registrationNumber', 'make', 'model']),
                  id: 'getVehicle',
                },
                {
                  label: 'Your cover',
                  value: `Comprehensive Cover starts on ${gw.getBaseData.getStartDate('DD/MM/YYYY')}`,
                  id: 'getCover',
                },
                {
                  label: 'Policyholder details',
                  value: gw.getAccountHolder.getDisplayName('withTitle'),
                  id: 'getPolicyHolder',
                },
                { label: 'Drivers', value: gw.getDrivers.getTotalDrivers('withDriversString'), id: 'getDrivers' },
              ] as any[]).map(item => (
                <SummaryAccordion key={item.label} title={item.label} value={item.value}>
                  <div style={{ borderBottom: '1px solid #eff0f0', marginBottom: 32 }}>
                    {getSummaryContent(item.id)?.map(summary => (
                      <div key={uniqeID()}>
                        {/* Note: To avoid confusion, the only info that will be in an array is drivers. all other info uses the code below */}
                        {Array.isArray(summary) ? (
                          <div key={uniqeID()}>
                            {summary.map((summaryItem, index) => (
                              <div
                                key={uniqeID()}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  padding: 16,
                                  borderTop: '1px solid #eff0f0',
                                  gap: 16,
                                  marginBottom: index === summary.length - 1 ? 32 : 0,
                                }}
                              >
                                <div>{summaryItem.label}</div>
                                <div
                                  style={{ display: 'flex', flexDirection: 'column', gap: 16, textAlign: 'right' }}
                                  dangerouslySetInnerHTML={{ __html: summaryItem.value }}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div
                            key={uniqeID()}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: 16,
                              gap: 16,
                              borderTop: '1px solid #eff0f0',
                            }}
                          >
                            <div>{summary.label}</div>
                            <div>{summary.value}</div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </SummaryAccordion>
              ))}
              {/* Addons dropdown only */}
              <SummaryAccordion title={'Add-ons'} value={gw.getIncludedAddons.getSelectedAddonsAsString()}>
                {gw.getIncludedAddons.getQuoteAddonSummary().map(item => (
                  <div
                    key={uniqeID()}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: 16,
                      borderTop: '1px solid #eff0f0',
                    }}
                  >
                    <div>{item.name}</div>
                    <div>{item.selected ? <CheckIcon /> : <CloseIcon color="secondary" />}</div>
                  </div>
                ))}
              </SummaryAccordion>
            </Grid>

            <Grid item xs={12}>
              <RenewalPolicyDocuments
                docsForAllPolicies={documentsForAllPolicies}
                docsForAddons={documentsForAddons}
                docForBreakdown={getBreakdownDoc()}
                addons={addons}
                showNew={now.isSameOrAfter(showNew)}
              />
            </Grid>

            <Grid item xs={12}>
              <Title title="Choose how to receive these documents" />

              <Typography>
                {`Thanks for helping us to reduce our environmental impact. You're currently set up to receive your documents electronically. If you wish to change this to receive your documents by post, you can do so here in Your Portal in ‘My Account’. You can also ask us for a paper copy of a specific document, instead of opting in for post entirely.`}
              </Typography>
            </Grid>
          </Grid>

          <Box pb={4} />
        </Section>
      </Layout>

      <Layout background="#eff0f0">
        <Box pt={8} pr={2.5} pl={2.5} pb={4}>
          <Grid container justify="center" spacing={2}>
            <Grid item>
              <Button variant="outlined" color="secondary" onClick={() => history.push('/renewal-your-quote')}>
                Go back
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={() => onContinue()}>
                Continue
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Layout>
    </>
  )
}

export default RenewalDocuments
