import { makeStyles, Typography } from '@material-ui/core'
import Title from 'components/ui/Title'

import DocumentIcon from '@material-ui/icons/Description'
import { IncludedAddOn } from 'guidewire/policy/types/includedAddon'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 16,
    marginBottom: 32,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexDirection: 'row',
      columnGap: 16,
    },
  },
  text: {
    flexBasis: '100%',
    marginBottom: 16,
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexBasis: '40%',
    },
  },
  documents: {
    flexBasis: '100%',
    marginTop: 5,
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexBasis: '60%',
    },
  },
}))

export interface RenewalPolicyDocumentsProps {
  addons: IncludedAddOn[]
  docsForAllPolicies: { label: string; documentUrl: string }[]
  docsForAddons: { label: string; documentUrl: string; addonId: string }[]
  docForBreakdown?: { label: string; documentUrl: string }
  showNew?: boolean
}

const RenewalPolicyDocuments = ({
  docsForAllPolicies,
  docsForAddons,
  docForBreakdown,
  addons,
  showNew = false
}: RenewalPolicyDocumentsProps) => {
  const classes = useStyles()

  const PCPDC3 =
    'https://assets.ctfassets.net/numinvtctmda/6qBToLDi5L0MPUtX40DaDG/a3703562a36caa7bbbe86f21a1a476bb/TYA_PCPD_v4.0.pdf'
  const PCPDC4 =
    'https://assets.ctfassets.net/numinvtctmda/6AJsK5H1untCWODvKvKdtX/16b94daaf95f2c38df76c930ed4b6633/TYA_PCPD_v5_Key_Provider_Change.pdf '

  const IPID2 =
    'https://assets.ctfassets.net/numinvtctmda/3WpadNErOSsaV1PyajWOPx/67f93fc47e36f0b6c020ba3ea292eb69/TYA_KEYS_IPID_v2.0.pdf'

  const IPID1 =
    'https://assets.ctfassets.net/numinvtctmda/4PlZIC9rT2papTvrOBMNoG/44b9fa71c03d2f6a2ae4dae0a897b901/TYA_Keys_IPID_v2.0.pdf'

  const getHrefForDocument = (item: { label: any; documentUrl: any }) => {
    switch (item.label) {
      case 'Private Car Policy Document ':
        return showNew ? PCPDC4 : PCPDC3
      case 'Key Protect Insurance Product Information Document':
        return showNew ? IPID2 : IPID1
      default:
        return item.documentUrl
    }
  }

  return (
    <>
      <Title title="Policy documents" />

      <div className={classes.container}>
        <div className={classes.text}>
          <Typography>{`Here are your car insurance documents for your policy, based on your renewal start date. Please read these documents to ensure that this policy meets your needs and you are aware of the benefits and exclusions.`}</Typography>
        </div>

        <div className={classes.documents}>
          {docsForAllPolicies.map(item => (
            <a
              key={item.label}
              href={getHrefForDocument(item)}
              target="__blank"
              style={{ color: '#282830', textDecoration: 'none' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '8px 16px',
                  border: '1px solid #d8d8d8',
                  marginBottom: 16,
                }}
              >
                <Typography>{item.label}</Typography>
                <DocumentIcon color="secondary" />
              </div>
            </a>
          ))}

          {docsForAddons.map(item => {
            if (addons.find(addOn => item.addonId === addOn.codeIdentifier && addOn.selected))
              return (
                <a
                  key={item.label}
                  href={getHrefForDocument(item)}
                  target="__blank"
                  style={{ color: '#282830', textDecoration: 'none' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '8px 16px',
                      border: '1px solid #d8d8d8',
                      marginBottom: 16,
                    }}
                  >
                    <Typography>{item.label}</Typography>
                    <DocumentIcon color="secondary" />
                  </div>
                </a>
              )
          })}

          {docForBreakdown !== undefined && (
            <a href={docForBreakdown.documentUrl} target="__blank" style={{ color: '#282830', textDecoration: 'none' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '8px 16px',
                  border: '1px solid #d8d8d8',
                  marginBottom: 16,
                }}
              >
                <Typography>{docForBreakdown.label}</Typography>
                <DocumentIcon color="secondary" />
              </div>
            </a>
          )}
        </div>
      </div>
    </>
  )
}

export default RenewalPolicyDocuments
