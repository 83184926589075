import { handleActions } from 'redux-actions'

import {
  showMTAModalAction,
  closeMTAModalAction,
  continueAddressChangeAction,
  cancelMTAChangesAction,
  confirmMTAChangesAction,
  continueCarChangeAction,
  continueAddDriverAction,
  showExecuteMTAModalAction,
  executeMTAChangesAction,
  deleteAddedDriverAction,
  multiMTAAction,
  resetAction,
  setShowExecuteModalAction,
} from './actions'

const defaultState = {
  showMTAModal: false,
  redriect: undefined,
  startDate: '',
  changedAddress: undefined,
  homeOwner: '',
  dayTimeParking: '',
  nightTimeParking: '',
  addedDrivers: [],
  changedCar: undefined,
  carPurchaseDate: null,
  marketValue: 0,
  hasBeenModified: 'none',
  securityDevices: 'none',
  classOfUse: '',
  registeredKeeper: '',
  securityTracker: '',
  imported: '',
  rightHandDrive: '',
  lessThanEightSeats: '',
  mtaResponse: undefined,
  showMTAConfirmModal: false,
  showMTAExecuteModal: false,
  paymentURL: '',
  jobId: '',
}

export default handleActions(
  {
    [showMTAModalAction.success]: (
      state,
      {
        payload,
      }: {
        payload: {
          showMTAModal: boolean
          redirect: string
        }
      },
    ) => {
      return {
        ...defaultState,
        showMTAModal: payload.showMTAModal,
        redirect: payload.redirect,
      }
    },
    [showExecuteMTAModalAction.success]: state => {
      return {
        ...state,
        showMTAExecuteModal: true,
      }
    },
    [closeMTAModalAction.success]: () => {
      return defaultState
    },
    [cancelMTAChangesAction.success]: () => {
      return defaultState
    },
    [multiMTAAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        jobId: payload.jobId,
      }
    },
    [setShowExecuteModalAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        showMTAExecuteModal: payload,
      }
    },
    [executeMTAChangesAction.success]: (state, { payload }: { payload: any }) => {
      if (payload.paymentURL) {
        return {
          ...state,
          paymentURL: payload.paymentURL,
          showMTAConfirmModal: false,
          showMTAExecuteModal: false,
        }
      } else {
        return {
          ...state,
          showMTAConfirmModal: false,
          showMTAExecuteModal: false,
        }
      }
    },
    [executeMTAChangesAction.error]: () => {
      return defaultState
    },
    [confirmMTAChangesAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        mtaResponse: payload,
        jobId: payload.jobId,
        showMTAConfirmModal: false,
      }
    },
    [confirmMTAChangesAction.error]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        showMTAConfirmModal: false,
      }
    },
    [continueAddressChangeAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        startDate: payload.startDate,
        changedAddress: payload.addressSelect,
        homeOwner: payload.homeOwner,
        dayTimeParking: payload.dayTimeParking,
        nightTimeParking: payload.nightTimeParking,
        showMTAConfirmModal: payload.showMTAConfirmModal,
      }
    },
    [deleteAddedDriverAction.success]: (state, { payload }: { payload: any }) => {
      const newArray = state.addedDrivers
      newArray.splice(payload.index, 1)
      return {
        ...state,
        addedDrivers: newArray,
      }
    },
    [continueCarChangeAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        startDate: payload.startDate,
        changedCar: payload.selectedVehicle,
        carPurchaseDate: payload.carPurchaseDate,
        marketValue: payload.marketValue,
        hasBeenModified: payload.hasBeenModified,
        securityDevices: payload.securityDevices,
        dayTimeParking: payload.dayTimeParking,
        nightTimeParking: payload.nightTimeParking,
        classOfUse: payload.classOfUse,
        registeredKeeper: payload.registeredKeeper,
        securityTracker: payload.securityTracker,
        imported: payload.imported,
        rightHandDrive: payload.rightHandDrive,
        lessThanEightSeats: payload.lessThanEightSeats,
        showMTAConfirmModal: payload.showMTAConfirmModal,
      }
    },
    [continueAddDriverAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        startDate: payload.startDate,
        showMTAConfirmModal: payload.showMTAConfirmModal,
      }
    },
    [resetAction.success]: () => {
      return defaultState
    },
  },
  defaultState,
)
