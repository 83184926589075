import moment from 'moment'
import React from 'react'

import { Box, Button, Grid } from '@material-ui/core'

import BasicAddonSection from 'components/renewal/BasicAddonSection'
import Layout from 'components/ui/Layout'
import BreakdownCover from 'components/renewal/BreakdownCover'
import Section from 'components/ui/Section'

import { getContentById, history } from 'utils'
import { renewalQuote } from 'guidewire/policy/quote/renewalQuote'

import { EndPoint } from 'types/endpoint'
import { ContentType, LabelValuePair } from 'types/contentTypes'
import { dateObjectToMoment } from 'guidewire/formatTools'

export interface Props {
  quote: any
  content: ContentType[]
  policyId: LabelValuePair
  index: number
  updateCoveragesAction: (request: any) => void
  policySelectAction: ({ index, policyId }: { index: number; policyId: string }) => void
}

const RenewalAddonPage = ({ quote, content, policyId, index, updateCoveragesAction, policySelectAction }: Props) => {
  const [refreshPolicies, setRefreshPolicies] = React.useState(false)

  const gw = renewalQuote(quote)
  const addonSummary = gw.getIncludedAddons.getQuoteAddonSummary()

  // NCD
  const ncdYears: string = gw.getVehicle.getNcdYears('withYears') as string
  const showNcdProtection = gw.getVehicle.getNcdProtectionAllowed()
  const ncdProtection = gw.getVehicle.getNcdProtection()
  const ncdCosts = gw.getVehicle.getNcdCostsAsAddon()

  const vehicle = quote?.vehicles[0]
  const vehicleRegDate = moment({
    year: vehicle.firstRegisteredYear,
    month:
      parseInt(
        moment()
          .month(vehicle.firstRegisteredMonth)
          .format('M'),
      ) | 0,
    day: 1,
  })
  // Breakdown
  //show breakdown when the vehicle is not over 15 years at the start of the new renewal period
  const showBreakdown =
    gw.getIncludedAddons.isBreakdownAvailable() &&
    moment(quote.baseData.periodStartDate).diff(vehicleRegDate, 'years') <= 15
  const breakdown = addonSummary.find(
    ({ codeIdentifier, selected }) => codeIdentifier.includes('Level') && selected === true,
  )
  // If selected the string will be one of three options "Level1", "Level2", "Level3"
  const breakdownSelected = breakdown?.selected === true ? breakdown.codeIdentifier : 'none'

  const breakdownCosts = addonSummary.filter(({ codeIdentifier }) => codeIdentifier.includes('Level'))


  // Content
  const addonCostPerYearText = getContentById(content, 'addonCostPerYearText').subtitle || ''

  // Dont forget this
  const premium = quote.bindData.selectedPaymentPlan.totalPremiumRPT.amount.toFixed(2)

  //
  const onUpdateCoverage = (id: string, name?: string, code?: string) => {
    const requestObject = gw.createUpdateCoveragesRequest(id, name, code)

    updateCoveragesAction({ requestObject, quoteId: policyId.value })

    setRefreshPolicies(true)
  }

  const onRemoveCoverage = (id: string) => {
    const requestObject = gw.createRemoveCoverageRequest(id)

    updateCoveragesAction({ requestObject, quoteId: policyId.value })

    setRefreshPolicies(true)
  }

  const now = dateObjectToMoment(quote.baseData.periodStartDate)

  const showNewAfterNow = moment('20231229', 'YYYYMMDD')

  React.useEffect(() => {
    // This makes sure that if the user makes any changes, they are reflected on the My Policy / Renewal pages
    if (refreshPolicies === true) {
      policySelectAction({ index, policyId: policyId.value })
      setRefreshPolicies(false)
    }
  }, [quote])

  return (
    <>
      <Layout background="white">
        {/* Desktop Premium Price */}
        <Box style={{ position: 'relative' }} display={{ xs: 'none', sm: 'block' }}>
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              justifyContent: 'center',
              left: 0,
              right: 0,
              backgroundColor: 'currentcolor',
              zIndex: 99,
              maxWidth: 1440,
              margin: '0 auto',
            }}
          >
            <h2 style={{ color: 'white', margin: 8 }}>{'Your premium is £' + premium}</h2>
          </div>
        </Box>
        {/* Mobile Premium Price */}
        <Box style={{ position: 'relative' }} display={{ xs: 'block', sm: 'none' }}>
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              justifyContent: 'center',
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'currentcolor',
              zIndex: 99,
              maxWidth: 1440,
              margin: '0 auto',
            }}
          >
            <h2 style={{ color: 'white', margin: 8 }}>{'Your premium is £' + premium}</h2>
          </div>
        </Box>

        <Box pb={8} display={{ xs: 'none', sm: 'block' }} />

        <Section>
          <Grid container spacing={4}>
            {/* NCD is technically an addon but its values come from the vehicle object, not the included addons array */}
            {showNcdProtection && (
              <Grid item xs={12}>
                <BasicAddonSection
                  content={getContentById(content, 'addonNcdProtection')}
                  ncdYears={ncdYears}
                  addonCostPerYearText={addonCostPerYearText}
                  onAddon={(id: string) => onUpdateCoverage(id)}
                  onRemove={(id: string) => onUpdateCoverage(id)}
                  codeIdentifier="addonNcdProtection"
                  costs={ncdCosts}
                  selected={ncdProtection}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <BasicAddonSection
                content={getContentById(content, 'PMDrivingAbroadCov')}
                addonCostPerYearText={addonCostPerYearText}
                onAddon={(id: string) => onUpdateCoverage(id)}
                onRemove={(id: string) => onUpdateCoverage(id)}
                costs={addonSummary.find(item => item.codeIdentifier === 'PMDrivingAbroadCov')?.costs}
                codeIdentifier="PMDrivingAbroadCov"
                selected={addonSummary.find((item: any) => item.codeIdentifier === 'PMDrivingAbroadCov')?.selected}
                showNewAfterNow={now.isSameOrAfter(showNewAfterNow)}
              />
            </Grid>

            {showBreakdown && (
              <Grid item xs={12}>
                <BreakdownCover
                  selectedLevel={breakdownSelected}
                  onAddon={(id: string, name: string, code: string) => onUpdateCoverage(id, name, code)}
                  onRemove={(id: string) => onRemoveCoverage(id)}
                  breakdownCosts={breakdownCosts}
                  periodStartDate={quote.baseData.periodStartDate}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <BasicAddonSection
                content={getContentById(content, 'PMLegalExpensesCov')}
                addonCostPerYearText={addonCostPerYearText}
                onAddon={(id: string) => onUpdateCoverage(id)}
                onRemove={(id: string) => onRemoveCoverage(id)}
                costs={addonSummary.find(item => item.codeIdentifier === 'PMLegalExpensesCov')?.costs}
                codeIdentifier="PMLegalExpensesCov"
                selected={addonSummary.find((item: any) => item.codeIdentifier === 'PMLegalExpensesCov')?.selected}
                showNewAfterNow={now.isSameOrAfter(showNewAfterNow)}
              />
            </Grid>

            <Grid item xs={12}>
              <BasicAddonSection
                content={getContentById(content, 'TMEnhancedCourtesyCarCov')}
                addonCostPerYearText={addonCostPerYearText}
                onAddon={(id: string) => onUpdateCoverage(id)}
                onRemove={(id: string) => onRemoveCoverage(id)}
                costs={addonSummary.find(item => item.codeIdentifier === 'TMEnhancedCourtesyCarCov')?.costs}
                codeIdentifier="TMEnhancedCourtesyCarCov"
                selected={
                  addonSummary.find((item: any) => item.codeIdentifier === 'TMEnhancedCourtesyCarCov')?.selected
                }
                showNewAfterNow={now.isSameOrAfter(showNewAfterNow)}
              />
            </Grid>

            <Grid item xs={12}>
              <BasicAddonSection
                content={getContentById(content, 'PMTrailerCov')}
                addonCostPerYearText={addonCostPerYearText}
                onAddon={(id: string) => onUpdateCoverage(id)}
                onRemove={(id: string) => onRemoveCoverage(id)}
                costs={addonSummary.find(item => item.codeIdentifier === 'PMTrailerCov')?.costs}
                codeIdentifier="PMTrailerCov"
                selected={addonSummary.find((item: any) => item.codeIdentifier === 'PMTrailerCov')?.selected}
                showNewAfterNow={now.isSameOrAfter(showNewAfterNow)}
              />
            </Grid>
          </Grid>
          <Box pt={4} />
        </Section>
      </Layout>

      <Layout background="#eff0f0">
        <Box pt={8} pr={2.5} pl={2.5} pb={4}>
          <Grid container justify="center" spacing={2}>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={() => history.push(EndPoint.RENEWAL_YOUR_QUOTE)}>
                Continue
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Layout>
    </>
  )
}

export default RenewalAddonPage
